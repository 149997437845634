import { Pipe, PipeTransform } from '@angular/core';

import { TreeLayer } from '../stratums.interface';

/**
 * Проверяет наличие узлов в структуре данных по их идентификатору.
 * @param {string} id - Идентификатор узла.
 * @param {Object} options - Объект с параметрами.
 * @param {string} [options.value] - Значение для поиска.
 * @param {Object} options.groups - Объект с группами узлов.
 * @param {Object} options.nodes - Объект с узлами.
 * @returns {boolean} - Возвращает true, если узлы найдены, иначе false.
 */
export const hasNodes = (
  id: string,
  {
    value,
    groups,
    nodes,
  }: {
    value?: string;
    groups: Record<TreeLayer<'group'>['id'], TreeLayer<'group'>>;
    nodes: Record<TreeLayer<'node'>['id'], TreeLayer<'node'>>;
  },
): boolean => {
  if (!value) {
    if (nodes[id]) {
      return !!nodes[id];
    } else {
      if (groups[id]?.childrenIds) {
        return !!groups[id].childrenIds.filter((childrenId) =>
          hasNodes(childrenId, {
            value,
            groups,
            nodes,
          }),
        ).length;
      } else {
        return false;
      }
    }
  } else {
    const node = nodes[id];

    if (node) {
      if (!node.label) {
        return false;
      }

      return node.label.toLowerCase().trim().includes(value.toLowerCase().trim());
    } else {
      if (groups[id]?.childrenIds) {
        return !!groups[id].childrenIds.filter((childrenId) =>
          hasNodes(childrenId, {
            value,
            groups,
            nodes,
          }),
        ).length;
      } else {
        return false;
      }
    }
  }
};

/**
 * Пользовательский конвейер для проверки наличия узлов
 * @Pipe({
 *   name: 'hasNodes',
 *   standalone: true,
 * })
 */
/**
 * Проверяет наличие узлов с заданным значением в указанных группах и узлах
 * @param {string} id - Идентификатор узла
 * @param {Object} options - Параметры для поиска
 * @param {string} [options.value] - Значение для поиска
 * @param {Record<string, TreeLayer<'group'>>} options.groups - Группы узлов
 * @param {Record<string, TreeLayer<'node'>>} options.nodes - Узлы
 * @returns {boolean} - Возвращает true, если узел с заданным значением найден, иначе false
 */

@Pipe({
  name: 'hasNodes',
  standalone: true,
})
export class HasNodesPipe implements PipeTransform {
  transform(
    id: string,
    {
      value,
      groups,
      nodes,
    }: {
      value?: string;
      groups: Record<TreeLayer<'group'>['id'], TreeLayer<'group'>>;
      nodes: Record<TreeLayer<'node'>['id'], TreeLayer<'node'>>;
    },
  ): boolean {
    return hasNodes(id, { value, groups, nodes });
  }
}
