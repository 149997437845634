import { type } from '@ngrx/signals';
import { entityConfig } from '@ngrx/signals/entities';

import { Status } from '../../../../enums/status.enum';
import { Model, Question, Stratum, TreeLayer } from '../stratums.interface';

export type Event = { date: string; isoDate: string; questionsIds: Question['id'][] };
export type Events = Record<Stratum['category'], Event[]>;

export const layersNodeConfig = entityConfig({
  entity: type<TreeLayer<'node'>>(),
  collection: 'layersNode',
  selectId: (item) => item.id,
});

export const layersGroupConfig = entityConfig({
  entity: type<TreeLayer<'group'>>(),
  collection: 'layersGroup',
  selectId: (item) => item.id,
});

export type StratumsState = {
  status: Status;
  error: string | null;
  stratums: Record<Stratum['id'], Stratum>;
  stratumsIds: Stratum['id'][];
  questions: Record<Question['id'], Question>;
  questionsIds: Question['id'][];
  models: Record<Model['guid'], Model>;
  // array Record<Stratum['start_datetime_msk'], Question['id'][]> sorted by day ASC(сортировки по возрастанию)
  events: Events;
  categories: Stratum['category'][];
  expanded: Record<string, true>;
  takenDecisionsStatus: Status;
  rootGroupIds: string[];
  checkedNodesExternalIdsStatus: Status;
  checkedNodesExternalIds: TreeLayer<'node'>['externalId'][];
};
