import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { Injectable, computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withState } from '@ngrx/signals';
import { setEntities, updateEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { forkJoin, mergeMap, pipe, switchMap, tap } from 'rxjs';

import { BackendEventsService } from '@services/backend-events.service';

import { BuildingCard } from '../../3dmodels/models3d.interface';
import { Models3dStoreService } from '../../3dmodels/store/models3d.store.service';
import { Status } from '../../../../enums/status.enum';
import { deleteObjectProperty } from '../../../../utils/immutable.util';
import { hasNodes } from '../pipes/has-nodes.pipe';
import { Question, Stratum, TreeLayer } from '../stratums.interface';
import { StratumsService } from '../stratums.service';
import { Event, Events, StratumsState, layersGroupConfig, layersNodeConfig } from './stratums.store.type';

/**
 * Класс для хранения данных слоев
 * @class
 */
@Injectable({ providedIn: 'root' })
export class StratumsStore extends signalStore(
  { protectedState: false },
  withDevtools('stratums'),
  withState<StratumsState>({
    status: Status.UNINITIALIZED,
    error: null,
    stratums: {},
    stratumsIds: [],
    categories: [],
    questions: {},
    questionsIds: [],
    models: {},
    events: {} as Events,
    expanded: {},
    takenDecisionsStatus: Status.UNINITIALIZED,
    rootGroupIds: [],
    checkedNodesExternalIds: [],
    checkedNodesExternalIdsStatus: Status.UNINITIALIZED,
  }),
  withEntities(layersNodeConfig),
  withEntities(layersGroupConfig),
) {
  /**
   * Сервис для работы с слоями
   * @type {StratumsService}
   */
  readonly #stratumsService = inject(StratumsService);

  /**
   * Сервис для работы с событиями бэкенда.
   * @type {BackendEventsService}
   */
  readonly #backendEventsService = inject(BackendEventsService);

  /**
   * Получает доступ к зданиям из сервиса Models3dService и сохраняет их в приватное свойство #buildings.
   * @type {Models3dService.buildings}
   */
  readonly #buildings = inject(Models3dStoreService).models3dEntityMap;

  /**
   * Проверяет, загружены ли данные
   * @type {boolean}
   */
  readonly isUninitialized = computed(() => this.status() === Status.UNINITIALIZED);

  /**
   * Функция, которая возвращает значение вычисляемого свойства isLoaded.
   * isLoaded равен true, если статус не равен LOADING.
   * @returns {boolean} Значение вычисляемого свойства isLoaded.
   */
  readonly isLoaded = computed(() => this.status() !== Status.LOADING);

  /**
   * Поле, указывающее на то, что статус принятых решений не инициализирован.
   * @type {boolean}
   */
  readonly takenDecisionsStatusIsUninitialized = computed(() => this.takenDecisionsStatus() === Status.UNINITIALIZED);

  /**
   * Вычисляемое свойство, которое возвращает true, если статус не равен LOADING.
   * @returns {boolean} Загружен ли статус принятых решений.
   */
  readonly takenDecisionsStatusIsLoaded = computed(() => this.takenDecisionsStatus() !== Status.LOADING);

  /**
   * Создает слои данных на основе деревьев.
   * @param {TreeLayer<'node'>[]} dataLayers - Массив деревьев для создания слоев данных.
   * @returns {Observable<void>} - Observable, который выполняет создание слоев данных.
   */
  readonly createDataLayers = rxMethod<TreeLayer<'node'>[]>(
    pipe(
      mergeMap((dataLayers) => {
        this.addExternalIds(dataLayers.map((item) => item.externalId));

        return forkJoin(dataLayers.map((treeLayer) => this.#backendEventsService.createDataLayer(treeLayer))).pipe(
          tapResponse({
            next: () => {},
            error: console.error,
          }),
        );
      }),
    ),
  );

  /**
   * Метод для снятия флажков с данных.
   * @param {number[]} externalIds - Массив внешних идентификаторов данных.
   * @returns {Observable} - Observable объект.
   */
  readonly uncheckDataLayers = rxMethod<number[]>(
    pipe(
      mergeMap((externalIds) => {
        this.removeExternalIds(externalIds);
        return this.#backendEventsService.destroyDataLayers(externalIds).pipe(
          tapResponse({
            next: () => {},
            error: console.error,
          }),
        );
      }),
    ),
  );

  /**
   * Получает события
   * @method
   */
  private readonly getData = rxMethod<void>(
    pipe(
      tap(() => patchState(this, { status: Status.LOADING })),
      switchMap(() =>
        this.#stratumsService.getEvents().pipe(
          tapResponse({
            next: (data) => {
              const stratumsData = data.reduce(
                (acc, stratum) => {
                  acc.stratums[stratum.id] = stratum;
                  acc.stratumsIds.push(stratum.id);
                  stratum.questions.forEach((question) => {
                    acc.questions[question.id] = question;
                    question.models.forEach((model) => {
                      if (this.#buildings()[model.guid]) {
                        acc.models[model.guid] = model;
                      }
                    });
                  });

                  return {
                    ...acc,
                    ...this.filterIteration(
                      { categories: acc.categories, events: acc.events, questionsIds: acc.questionsIds },
                      stratum,
                      '',
                      this.#buildings(),
                    ),
                  };
                },
                {
                  events: {} as Events,
                  questionsIds: [],
                  categories: [],
                  stratums: {},
                  stratumsIds: [],
                  questions: {},
                  models: {},
                } as Pick<StratumsState, 'categories' | 'events' | 'models' | 'questions' | 'questionsIds' | 'stratums' | 'stratumsIds'>,
              );

              patchState(this, stratumsData);
            },
            error: console.error,
            finalize: () => patchState(this, { status: Status.LOADED }),
          }),
        ),
      ),
    ),
  );

  /**
   * Метод для получения структуры слоев дерева.
   *
   * @private
   * @readonly
   * @param {void} void - Параметр отсутствует
   * @returns {void}
   */
  private readonly getTreeLayers = rxMethod<void>(
    pipe(
      tap(() => patchState(this, { takenDecisionsStatus: Status.LOADING })),
      switchMap(() =>
        this.#stratumsService.getTreeLayers().pipe(
          tapResponse({
            next: (takenDecisions = []) => {
              const { groups, nodes } = takenDecisions.reduce<{ nodes: TreeLayer<'node'>[]; groups: TreeLayer<'group'>[] }>(
                (acc, item) => {
                  if (item.itemType === 'node') {
                    acc.nodes.push(item as TreeLayer<'node'>);
                  } else {
                    acc.groups.push({ ...item, childrenIds: [] } as TreeLayer<'group'>);
                  }

                  return acc;
                },
                { nodes: [], groups: [] },
              );

              patchState(this, setEntities(groups, layersGroupConfig));
              patchState(this, setEntities(nodes, layersNodeConfig));
              this.addChildren2LayerStructure(nodes, groups);
            },
            error: console.error,
            finalize: () => patchState(this, { takenDecisionsStatus: Status.LOADED }),
          }),
        ),
      ),
    ),
  );

  /**
   * Метод для получения внешних идентификаторов отмеченных узлов.
   * @private
   * @readonly
   * @param {void} param - Параметр метода.
   * @returns {void}
   */
  private readonly getCheckedNodesExternalIds = rxMethod<void>(
    pipe(
      tap(() => patchState(this, { checkedNodesExternalIdsStatus: Status.LOADING })),
      switchMap(() =>
        this.#backendEventsService.getCheckedNodesExternalIds().pipe(
          tapResponse({
            next: (checkedNodesExternalIds = []) => patchState(this, { checkedNodesExternalIds }),
            error: console.error,
            finalize: () => patchState(this, { checkedNodesExternalIdsStatus: Status.LOADED }),
          }),
        ),
      ),
    ),
  );

  /**
   * Выполняет действие по получению событий
   * @method
   */
  loadEventsAction(): void {
    this.isUninitialized() && this.getData();
  }

  /**
   * Загружает внешние идентификаторы отмеченных узлов.
   * Если статус отмеченных узлов равен UNINITIALIZED, то вызывается метод getCheckedNodesExternalIds().
   * @returns {void}
   */
  loadCheckedNodesExternalIds(): void {
    this.checkedNodesExternalIdsStatus() === Status.UNINITIALIZED && this.getCheckedNodesExternalIds();
  }

  /**
   * Загружает структуру слоев дерева.
   * Если статус принятых решений не инициализирован, то вызывает метод getTreeLayersStructure().
   *
   * @returns {void}
   */
  loadTreeLayers(): void {
    this.takenDecisionsStatusIsUninitialized() && this.getTreeLayers();
  }

  /**
   * Переключает свойство
   * @param {string} prop - Свойство для переключения
   * @method
   */
  toggle(prop: string): void {
    let expanded = this.expanded();
    if (expanded[prop]) {
      expanded = deleteObjectProperty(expanded, prop);
    } else {
      expanded = { ...expanded, [prop]: true };
    }

    patchState(this, { expanded });
  }

  /**
   * Удаляет внешние идентификаторы из массива checkedNodesExternalIds.
   * @param {number[]} externalIds - Массив внешних идентификаторов для удаления.
   * @returns {void}
   */
  removeExternalIds(externalIds: number[]): void {
    patchState(this, {
      checkedNodesExternalIds: this.checkedNodesExternalIds().filter((id) => !externalIds.includes(id)),
    });
  }

  /**
   * Фильтрует события по значению
   * @param {string} value - Значение для фильтрации
   * @returns {Pick<StratumsState, 'categories' | 'events' | 'questionsIds'>} - Отфильтрованные события
   */
  getFilteredData(value: string): Pick<StratumsState, 'categories' | 'events' | 'questionsIds'> {
    return this.stratumsIds().reduce(
      (acc, stratumsId) => {
        const stratum = this.stratums()[stratumsId];

        return {
          ...acc,
          ...this.filterIteration(
            { categories: acc.categories, events: acc.events, questionsIds: acc.questionsIds },
            stratum,
            value,
            this.#buildings(),
          ),
        };
      },
      { events: {} as Events, questionsIds: [], categories: [] } as Pick<StratumsState, 'categories' | 'events' | 'questionsIds'>,
    );
  }

  /**
   * Получает корневые группы принятых решений.
   * @param {string} value - Значение для фильтрации.
   * @returns {TreeLayer<'group'>[]} - Массив объектов корневых групп принятых решений.
   */
  getTakenDecisionsRootGroups(value = ''): TreeLayer<'group'>[] {
    return this.rootGroupIds().map((rootGroupId) => ({
      ...this.layersGroupEntityMap()[rootGroupId],
      childrenIds: (this.layersGroupEntityMap()[rootGroupId]?.childrenIds ?? []).filter((childrenId) =>
        hasNodes(childrenId, { value, groups: this.layersGroupEntityMap(), nodes: this.layersNodeEntityMap() }),
      ),
    }));
  }

  /**
   * Получает внешние идентификаторы узлов дерева для указанного слоя дерева.
   * @param {TreeLayer} treeLayer - Слой дерева, для которого нужно получить внешние идентификаторы узлов.
   * @returns {Array<string>} - Массив внешних идентификаторов узлов дерева.
   */
  getExternalIds(treeLayer: TreeLayer): TreeLayer<'node'>['externalId'][] {
    return this.getTreeLayersNodes(treeLayer).map((node) => node.externalId);
  }

  /**
   * Получает узлы слоев дерева.
   * @param {TreeLayer} treeLayer - Слой дерева.
   * @returns {TreeLayer<'node'>[]} - Массив узлов слоя дерева.
   */
  getTreeLayersNodes(treeLayer: TreeLayer): TreeLayer<'node'>[] {
    if (treeLayer.itemType === 'node') {
      return [treeLayer as TreeLayer<'node'>];
    } else if (treeLayer.itemType === 'group') {
      return this.findSubNodes(treeLayer.id);
    }

    return [];
  }

  /**
   * Находит все подузлы узла по его идентификатору.
   * @param {string} nodeId - Идентификатор узла, для которого нужно найти подузлы.
   * @param {TreeLayer<'node'>[]} treeLayersNodes - Массив узлов дерева, в котором нужно найти подузлы.
   * @returns {TreeLayer<'node'>[]} - Массив узлов дерева, представляющий все подузлы узла.
   */
  private findSubNodes(nodeId: string, treeLayersNodes: TreeLayer<'node'>[] = []): TreeLayer<'node'>[] {
    if (this.layersGroupEntityMap()[nodeId]) {
      return (this.layersGroupEntityMap()[nodeId].childrenIds ?? [])
        .map((childNodeId) => this.findSubNodes(childNodeId, treeLayersNodes))
        .flat();
    } else if (this.layersNodeEntityMap()[nodeId]) {
      return [...treeLayersNodes, this.layersNodeEntityMap()[nodeId]];
    }

    return [];
  }

  /**
   * Добавляет внешние идентификаторы к списку уже проверенных узлов.
   * @param {number[]} externalIds - Массив внешних идентификаторов, которые необходимо добавить.
   * @returns {void}
   */
  private addExternalIds(externalIds: number[]): void {
    patchState(this, { checkedNodesExternalIds: [...this.checkedNodesExternalIds(), ...externalIds] });
  }

  /**
   * Фильтрует итерацию по слоям
   *
   * @param {Pick<StratumsState, 'categories' | 'events' | 'questionsIds'>} param0 Объект с категориями, событиями и идентификаторами вопросов
   * @param {Stratum} stratum Стратум
   * @param {string} value Значение для фильтрации
   * @param {Record<string, BuildingCard>} buildings Объект с данными о зданиях
   * @returns {Pick<StratumsState, 'categories' | 'events' | 'questionsIds'>} Объект с отфильтрованными категориями, событиями и идентификаторами вопросов
   */
  private filterIteration(
    { categories, events, questionsIds: dataQuestionsIds }: Pick<StratumsState, 'categories' | 'events' | 'questionsIds'>,
    stratum: Stratum,
    value = '',
    buildings: Record<string, BuildingCard>,
  ): Pick<StratumsState, 'categories' | 'events' | 'questionsIds'> {
    const questionsNumbersLookupTable = stratum.questions.reduce<Record<Question['id'], number>>((acc, curr) => {
      acc[curr.id] = curr.number;
      return acc;
    }, {});

    let questionsIds = stratum.questions
      .reduce((acc, question) => {
        if (value) {
          if (
            buildings[question.models[0].guid] &&
            (question.name.toLowerCase().includes(value.toLowerCase()) ||
              question.models.some((model) => model.address.toLowerCase().includes(value.toLowerCase())))
          ) {
            acc.push(question.id);
          }
        } else {
          if (buildings[question.models[0].guid]) {
            acc.push(question.id);
          }
        }

        return acc;
      }, [] as string[])
      .sort((idPrev, idCurr) => questionsNumbersLookupTable[idPrev] - questionsNumbersLookupTable[idCurr]);

    if (!questionsIds.length) {
      return { categories, events, questionsIds: dataQuestionsIds };
    }

    if (!categories.includes(stratum.category)) {
      categories.push(stratum.category);
    }

    const date = new Date(stratum.start_datetime_msk).toLocaleDateString('ru-Ru');

    const event: Event = { date, isoDate: stratum.start_datetime_msk, questionsIds };

    if (!events[stratum.category]) {
      events[stratum.category] = [event];
    } else {
      const eventsList = events[stratum.category];

      const index = eventsList.findIndex((item) => item.date === date);

      if (index > -1) {
        events[stratum.category][index] = { ...eventsList[index], ...event };
      } else {
        events[stratum.category].push(event);
        events[stratum.category].sort((a, b) => new Date(b.isoDate).getTime() - new Date(a.isoDate).getTime());
      }
    }

    questionsIds = [...dataQuestionsIds, ...questionsIds];

    return { categories, events, questionsIds };
  }

  /**
   * Добавляет дочерние элементы в структуру слоя
   * @param {LayerStructure<'node'>[]} nodes - Массив узлов
   * @param {LayerStructure<'group'>[]} groups - Массив групп
   * @returns {void}
   */
  private addChildren2LayerStructure(nodes: TreeLayer<'node'>[], groups: TreeLayer<'group'>[]): void {
    const changes: Record<TreeLayer<'group'>['id'], TreeLayer<'group'>['id'][]> = {};
    const rootGroupIds: string[] = [];

    nodes.forEach((item) => {
      if (item.idParent) {
        changes[item.idParent] = changes[item.idParent] ? [...changes[item.idParent], item.id] : [item.id];
      }
    });

    groups.forEach((item) => {
      if (item.idParent) {
        changes[item.idParent] = changes[item.idParent] ? [...changes[item.idParent], item.id] : [item.id];
      } else {
        rootGroupIds.push(item.id);
      }
    });

    patchState(
      this,
      updateEntities(
        { ids: Object.keys(changes), changes: (group: TreeLayer) => ({ childrenIds: changes[group.id] ?? [] }) },
        layersGroupConfig,
      ),
      { rootGroupIds },
    );
  }
}
